<template>
  <div class="content-center">
    <div class="vx-row w-full mb-base c-center flex items-center justify-around">
      <div class="md:w-2/5 w-full flex">
        <vx-card :title="$t('AddDiscountSlab')">
          <div class="vx-row">
            <div class="vx-col w-full mt-10 mb-6">
              <vs-input v-validate="'required|min:3'" class="w-full mb-5" autofocus :placeholder="$t('DiscountPlanName') + '*'" v-model="discountPlan" />
<!--              <vx-input-group class="mb-base">-->
<!--                <template slot="prepend">-->
<!--                  <div class="prepend-text bg-primary">-->
<!--                    <span>{{$t('Discount')}}</span>-->
<!--                  </div>-->
<!--                </template>-->

<!--                <vs-input :placeholder="$t('Discount')" v-model="discountAmount" type="number" />-->
<!--                <template slot="append">-->
<!--                  <div class="append-text bg-primary">-->
<!--                    <span>$</span>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </vx-input-group>-->
            </div>
          </div>
          <div class="vx-row ml-2">
            <vs-button :disabled="!validateForm" class="" @click="addDiscount">{{ $t('Add') }}</vs-button>
            <div class="p-3"></div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddDiscountSlab',
  computed: {
    validateForm() {
      return !this.errors.any() && this.discountAmount !== '' && this.discountPlan !== ''
    },
  },
  data() {
    return {
      discountPlan: '',
      discountAmount: 0,
    }
  },
  methods: {
    addDiscount() {
      const payload = {
        discount_plan: this.discountPlan,
        discount: this.discountAmount,
      }
      this.$vs.loading()

      this.$store.dispatch('pad/addDiscountSlabs', payload).then((data) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message ? data.message : data.detail,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.$router.push({ name: 'discount-slabs' })
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.message ? error.message : error.detail,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },
  },
}
</script>

<style scoped>

</style>
